<template>
  <div class="bonuses-progress">
    <div class="bonuses-progress__line" :style="{ '--width': lineWidthStyle }"></div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{
    progress: number;
  }>();

  const lineWidthStyle = computed(() => {
    return `${props.progress}%`;
  });
</script>

<style src="~/assets/styles/components/bonuses/progress.scss" lang="scss" />
